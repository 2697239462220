import { Flavor } from '@move-frontend/utils';
import { format, set } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { config } from '../../../../config';

const defaultZone = config.timeZone;

export interface ILocalDateTime {
	date: DateString;
	time: ITime;
	zone: string;
}

export type DateString = Flavor<string, 'date-string'>;

export interface ITime {
	hours: number;
	minutes: number;
}

export function toLocalDateTime(
	date: Date,
	zone = defaultZone,
): ILocalDateTime {
	const localDate = utcToZonedTime(date, zone);

	return asLocalDateTime(localDate, zone);
}

/**
 * This function should rarely be used outside this module as it assumes a Date with a time zone shifted offset is provided.
 * Use `toLocalDateTime` instead to convert a Date to a ILocalDateTime object.
 */
export function asLocalDateTime(
	date: Date,
	zone = defaultZone,
): ILocalDateTime {
	return {
		date: createDateString(date),
		time: createTime(date),
		zone,
	};
}

export function fromLocalDateTime(localDateTime: ILocalDateTime): Date {
	const localDate = asDate(localDateTime);

	return zonedTimeToUtc(localDate, localDateTime.zone);
}

/**
 * NB: This function should rarely be used outside this module as it returns a Date with a time zone shifted offset.
 * Use `fromLocalDateTime` instead to convert a ILocalDateTime object to a Date.
 */
export function asDate({
	date: dateString,
	time: { hours, minutes },
}: ILocalDateTime): Date {
	const date = parseDateString(dateString);

	return set(date, {
		hours,
		minutes,
	});
}

export function createDateString(date: Date): DateString {
	return format(date, 'yyyy-MM-dd');
}

export function parseDateString(dateString: DateString): Date {
	const [year, month, date] = dateString
		.split('-')
		.map((part) => parseInt(part, 10));

	return set(new Date(), {
		year,
		month: month - 1, // month is zero-based
		date,
		hours: 0,
		minutes: 0,
		seconds: 0,
		milliseconds: 0,
	});
}

export function createTime(date: Date): ITime {
	return { hours: date.getHours(), minutes: date.getMinutes() };
}

export function getLocalTime(date: Date) {
	const { time } = toLocalDateTime(date);

	return time;
}

export function getLocalTimeInMinutes(date: Date) {
	const time = getLocalTime(date);

	return getTimeInMinutes(time);
}

export function getTimeInMinutes({ hours, minutes }: ITime) {
	return hours * 60 + minutes;
}

export function getLocalStartOfDay(date: Date): Date {
	const localDateTime = toLocalDateTime(date);

	localDateTime.time.hours = 0;
	localDateTime.time.minutes = 0;

	return fromLocalDateTime(localDateTime);
}

export function getLocalStartOfToday() {
	return getLocalStartOfDay(new Date());
}

export function isLocalSameDay(first: Date, second: Date) {
	return (
		getLocalStartOfDay(first).getTime() === getLocalStartOfDay(second).getTime()
	);
}
