import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { ISelectComponent, ISelectProps, Select } from '../../../../ui';
import { ITimeOption } from './TimeOption';

export interface ITimeSelectProps extends ISelectProps<ITimeOption> {
	onChange: (option: ITimeOption | null) => void;
}

export const TimeSelect: FC<ITimeSelectProps> = (props) => {
	useValidateSelectedItem(props);

	return <StyledTimeSelect {...props} />;
};

const CustomSelect = Select as ISelectComponent<ITimeSelectProps>;

export const StyledTimeSelect = styled(CustomSelect)`
	white-space: nowrap;
`;

function useValidateSelectedItem({
	selectedItem,
	items,
	onChange,
}: ITimeSelectProps) {
	useEffect(() => {
		if (!selectedItem) return;

		const isSelectedItemValid = items.some(
			(item) => item.value === selectedItem.value,
		);

		if (!isSelectedItemValid) {
			onChange(null);
		}
	}, [selectedItem, items]);
}
