import React from 'react';
import { maybeMap } from '@move-frontend/utils';
import { startOfDay } from 'date-fns';
import { MaybeDate, MaybeDateRange } from './MaybeDate';
import {
	asDate,
	asLocalDateTime,
	fromLocalDateTime,
	toLocalDateTime,
} from '../../i18n/date-time/LocalDateTime';
import { IStyledDatePickerProps, StyledDatePicker } from './StyledDatePicker';

import { ITranslationFunction } from '../../i18n/translate';
import { useTranslation } from '../../i18n/translate/useTranslation';

export type IDatePickerProps =
	| Omit<IDateRangePickerProps, 'translateFunction'>
	| Omit<ISingleDatePickerProps, 'translateFunction'>;

export type IDateRangePickerProps = IBaseDatePickerProps<true> &
	IDateRangeSelectionProps;

export type ISingleDatePickerProps = IBaseDatePickerProps<false> &
	ISingleDateSelectionProps;

interface IBaseDatePickerProps<
	WithRange extends boolean | undefined = undefined,
> extends Omit<
		IStyledDatePickerProps<WithRange>,
		'selected' | 'startDate' | 'endDate' | 'onChange'
	> {
	disableDatesBefore?: Date;
	translateFunction: ITranslationFunction;
}

interface IDateRangeSelectionProps {
	selectsRange: true;
	selected: MaybeDateRange;
	onChange: (range: MaybeDateRange) => void;
	translateFunction: ITranslationFunction;
}

interface ISingleDateSelectionProps {
	selectsRange?: never;
	selected: MaybeDate;
	onChange: (range: MaybeDate) => void;
	translateFunction: ITranslationFunction;
}

export const DatePicker = (props: IDatePickerProps) => {
	const { t } = useTranslation();

	return (
		<StyledDatePicker
			{...adaptDatePickerProps({ ...props, translateFunction: t })}
		/>
	);
};

function adaptDatePickerProps({
	disableDatesBefore,
	...props
}:
	| IDateRangePickerProps
	| ISingleDatePickerProps): IStyledDatePickerProps<boolean> {
	const adaptedProps = props.selectsRange
		? adaptDateRangePickerProps(props)
		: adaptSingleDatePickerProps(props);

	const filterDate = (date: Date) => {
		if (
			disableDatesBefore &&
			date.getTime() < startOfDay(toLocalDate(disableDatesBefore)).getTime()
		) {
			return false;
		}

		return props.filterDate ? props.filterDate(date) : true;
	};

	return {
		...adaptedProps,
		filterDate,
	};
}

const toLocalDate = (date: Date) => asDate(toLocalDateTime(date));

const fromLocalDate = (date: Date) => fromLocalDateTime(asLocalDateTime(date));

function adaptDateRangePickerProps({
	selected,
	onChange,
	translateFunction,
	...rest
}: IDateRangePickerProps): IStyledDatePickerProps<true> {
	const [localStartDate, localEndDate] = selected.map((date) =>
		maybeMap(date, toLocalDate),
	);

	return {
		startDate: localStartDate,
		endDate: localEndDate,
		onChange: ([newLocalStartDate, newLocalEndDate]: MaybeDateRange) => {
			const newRange: MaybeDateRange = [
				maybeMap(newLocalStartDate, fromLocalDate),
				maybeMap(newLocalEndDate, fromLocalDate),
			];

			onChange(newRange);
		},
		screenReaderText: translateFunction(
			'web_datepicker_range_screen_reader_text',
		),
		...rest,
	};
}

function adaptSingleDatePickerProps({
	selected,
	onChange,
	translateFunction,
	...rest
}: ISingleDatePickerProps): IStyledDatePickerProps<false> {
	const selectedLocalDate = maybeMap(selected, toLocalDate);

	return {
		selected: selectedLocalDate,
		onChange: (newLocalDate: MaybeDate) => {
			const newDate = maybeMap(newLocalDate, fromLocalDate) ?? null;

			onChange(newDate);
		},
		screenReaderText: translateFunction('web_datepicker_screen_reader_text'),
		...rest,
	};
}
