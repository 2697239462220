import React from 'react';
import BaseDatePicker, {
	ReactDatePickerProps,
	registerLocale,
} from 'react-datepicker';
import {
	CalendarIcon,
	Input,
	ITheme,
	StyledTextFieldErrorMessage,
	VisuallyHidden,
} from '../../../../ui';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import 'react-datepicker/dist/react-datepicker.css';
import datePickerEs from 'date-fns/locale/es';
import type { Locale as DatePickerTranslations } from 'date-fns';
import { locales } from '../../i18n/locale';
import { LocaleCode } from '../../i18n/locale/models/LocaleCode';
import { useLocale } from '../../i18n/locale/useLocale';
import { useTranslation } from '../../i18n/translate/useTranslation';

const datePickerLocales: Record<
	LocaleCode,
	DatePickerTranslations | undefined
> = {
	'en-US': undefined,
	'es-US': datePickerEs,
};

locales.forEach(({ code }) => {
	const datePickerLocale = datePickerLocales[code];

	if (datePickerLocale === undefined) return;

	registerLocale(code, datePickerLocale);
});

export interface IStyledDatePickerProps<
	WithRange extends boolean | undefined = undefined,
> extends ReactDatePickerProps<never, WithRange>,
		IDatePickerInputProps {
	className?: string;
}

export interface IDatePickerInputProps {
	invalid?: boolean;
	ariaLabel?: string;
	screenReaderText?: string;
}

export const DATEPICKER_SR_MESSAGE_ID = 'datepicker-sr-message';

export const StyledDatePicker = <
	WithRange extends boolean | undefined = undefined,
>({
	className,
	invalid,
	ariaLabel,
	disabled,
	placeholderText,
	inline,
	screenReaderText,
	...rest
}: IStyledDatePickerProps<WithRange>) => {
	const { code } = useLocale();
	const { t } = useTranslation();

	return (
		<StyledWrap className={className}>
			<BaseDatePicker
				locale={code}
				dateFormat="MMM d, yyyy"
				customInput={
					// Some props such as `disabled` will be passed by the date picker
					// Only custom props should be added explicitly here
					<Input
						invalid={invalid}
						aria-label={ariaLabel}
						data-allow-calendar="true"
						aria-haspopup="dialog"
						aria-controls="datepicker"
						inputMode="none"
					/>
				}
				formatWeekDay={(formattedDate: string) => formattedDate[0]}
				placeholderText={placeholderText}
				disabled={disabled}
				ariaInvalid={invalid ? 'true' : undefined}
				inline={inline}
				ariaDescribedBy={DATEPICKER_SR_MESSAGE_ID}
				{...rest}
			/>
			{screenReaderText && (
				<VisuallyHidden id={DATEPICKER_SR_MESSAGE_ID}>
					{screenReaderText}
				</VisuallyHidden>
			)}
			{!inline && <StyledIcon disabled={disabled} invalid={invalid} />}
			{invalid && (
				<StyledTextFieldErrorMessage role="alert">
					{t('parking_booking_error_invalid_date')}
				</StyledTextFieldErrorMessage>
			)}
		</StyledWrap>
	);
};

const StyledWrap = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;

	${getDatePickerStyles}
`;

const StyledIcon = styled(CalendarIcon)<{
	disabled?: boolean;
	invalid?: boolean;
}>`
	position: absolute;
	right: 16px;
	top: ${({ invalid }) => (invalid ? '34%' : '50%')};
	transform: translateY(-50%);
	opacity: 0.8;
	pointer-events: none;

	${({ disabled }) =>
		disabled &&
		`
		opacity: 0.2;
	`}
`;

interface IDatePickerStyles {
	theme: ITheme;
}

export function getDatePickerStyles({ theme }: IDatePickerStyles) {
	return css`
		.react-datepicker {
			background: #fff;
			border: none;
			border-radius: 4px;
			box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
				0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
			font-family: ${theme.typography.body.fontFamily};
			padding: 24px;
		}

		.react-datepicker__input-container {
			input {
				font-family: ${theme.typography.body.fontFamily};
			}
		}

		.react-datepicker__header {
			background: #fff;
			border: none;
			padding: 0;
		}

		.react-datepicker__navigation {
			top: 20px;
			width: 24px;
			height: 24px;
		}

		.react-datepicker__navigation--previous {
			left: 22px;
		}

		.react-datepicker__navigation--next {
			right: 22px;
		}

		.react-datepicker__day-names {
			margin: 16px 0 0;
		}

		.react-datepicker__day-name {
			color: rgba(0, 0, 0, 0.6);
			font-size: 12px;
			font-weight: 500;
		}

		.react-datepicker__day,
		.react-datepicker__day-name {
			width: 44px;
			height: 44px;
			line-height: 46px;
			margin: 2px 0;
			border-radius: 0;
			padding: 0;
			text-align: center;
		}

		.react-datepicker__day--disabled {
			opacity: 0.2;
		}

		.react-datepicker__month {
			margin: 0;
		}

		.react-datepicker__current-month {
			font-weight: normal;
			color: rgba(0, 0, 0, 0.6);
			font-size: 14px;
		}

		.react-datepicker__navigation-icon::before {
			border-width: 2px 2px 0 0;
			border-color: rgba(0, 0, 0, 0.6);
		}

		.react-datepicker__triangle {
			display: none;
		}

		.react-datepicker__day {
			color: rgba(0, 0, 0, 0.87);
			border-radius: 50%;

			&:hover {
				border-radius: 50%;
			}
		}

		.react-datepicker__day--in-range,
		.react-datepicker__day--selecting-range-start,
		.react-datepicker__day--range-start,
		.react-datepicker__day--range-end {
			position: relative;
			border-radius: 50%;
			transform-style: preserve-3d;
			color: rgba(0, 0, 0, 0.87);

			&:hover {
				border-radius: 50%;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				background: rgb(206 217 239);
				width: 100%;
				height: 100%;
				border-radius: 0;
				transform: translateZ(-1px);
			}
		}

		.react-datepicker__day--in-selecting-range,
		.react-datepicker__day--in-range {
			background: rgb(206 217 239) !important;
			color: rgba(0, 0, 0, 0.87);

			&:hover {
				background: rgb(206 217 239) !important;
			}
		}

		.react-datepicker__day--in-selecting-range {
			border-radius: 0;

			&:hover {
				border-radius: 0;
			}
		}

		.react-datepicker__day--selecting-range-start,
		.react-datepicker__day--range-start,
		.react-datepicker__day--range-end {
			background: #366bbf !important;
			color: #fff;
			border-radius: 50%;

			&:before {
				border-radius: 50% 0 0 50%;
			}

			&:hover {
				background: #3466b7 !important;
				border-radius: 50%;
			}
		}

		.react-datepicker__day--range-end {
			&:before {
				border-radius: 0 50% 50% 0;
			}

			&.react-datepicker__day--range-start:before {
				border-radius: 50%;
			}
		}

		.react-datepicker__day--keyboard-selected {
			background: none;
		}
	`;
}
