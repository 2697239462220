import styled from '@emotion/styled';
import Responsive from '../../../ui/utils/Responsive';

export const Divider = styled.hr`
	margin: 80px 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin: 40px 0;
	}
`;
