import { IDropdownItemWithHint } from '../../../../ui';
import { format, isValid, parse } from 'date-fns';
import {
	createTime,
	getTimeInMinutes,
} from '../../i18n/date-time/LocalDateTime';

export interface ITimeOption extends IDropdownItemWithHint {
	value: string;
	stringValue: string;
	timeInMinutes: number;
}

export type MaybeTimeOption = ITimeOption | null;

export function createTimeOption(value: string): ITimeOption {
	const parsedValue = parseValue(value);

	if (!parsedValue) {
		throw new Error(`Invalid time value: '${value}'`);
	}

	return {
		type: 'item',
		value,
		timeInMinutes: getTimeInMinutes(createTime(parsedValue)),
		stringValue: formatTime(parsedValue),
	};
}

function parseValue(value: string): Date | undefined {
	const date = parse(value, 'HH:mm', new Date());

	return isValid(date) ? date : undefined;
}

function formatTime(date: Date) {
	return format(date, 'h:mm a');
}
